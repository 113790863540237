@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    height: 100vh;
    box-sizing: border-box;
  }
  body {
    height: 100vh;
    @apply bg-[#20c8d6];
    @apply text-[#d9d9d9];
    background-image: url("./assets/bg2.jpg");
    font-family: "rapid";
    
    
  }
}

.test {
  width: 100%!important;
  
}

@font-face {
  font-family: "Rapid";
  src: url("./fonts/RapidtechDemoRegular.ttf");
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 3px !important;
  height: 0px !important;
  border-radius: 2px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: gray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(75, 45, 209);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(93, 66, 214);
}